import React from "react";

import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Header from "root/sections/Header";
import Footer from "root/sections/Footer";

const MobilityServicesPage = () => {
  return (
    <div>
      <SEO title="Drivit | Mobility Services" />
      <Layout>
        <Header currentPage="mobility-services" background="white" />
        <Footer />
      </Layout>
    </div>
  );
};

export default MobilityServicesPage;
